import axios from "axios";
import networkService from "../api.js";
import toaster from "../modules/toaster.js";

const defaultState = {
  activities: [],
  activity: null,
  error: null,
  isPending: null,
  loaded: false,
  singleActivity: {},
  activityImage: "",
};
const LOAD_ACTIVITIES_LIST = "LOAD_ACTIVITIES_LIST";
const LOAD_ACTIVITIES_LIST_PENDING = "LOAD_ACTIVITIES_LIST_PENDING";
const LOAD_ACTIVITIES_LIST_ERROR = "LOAD_ACTIVITIES_LIST_ERROR";
const SET_SELECTED_ACTIVITY = "SET_SELECTED_ACTIVITY";
const EDIT_ACTIVITY = "EDIT_ACTIVITY";
const DELETE_ACTIVITY = "DELETE_ACTIVITY";
const GET_SINGLE_ACTIVITY = "GET_SINGLE_ACTIVITY";
const CREATE_ACTIVITY = "CREATE_ACTIVITY";
const ACTIVITY_IMAGE = "ACTIVITY_IMAGE";
const DELETE_ACTIVITIES = "DELETE_ACTIVITIES";

export const loadActivitiesList = () => (dispatch, getState) => {
  if (!getState().Activities.loaded) {
    dispatch({ type: LOAD_ACTIVITIES_LIST_PENDING });
    networkService
      .send("activities")
      .then((res) => {
        dispatch({
          type: LOAD_ACTIVITIES_LIST,
          activities: res.data.activities,
        });
      })
      .catch((error) => {
        dispatch({ type: LOAD_ACTIVITIES_LIST_ERROR, error });
      });
  }
};
export const setSelectedActivity = (activity) => {
  return {
    type: SET_SELECTED_ACTIVITY,
    activity: activity,
  };
};

export const createActivity = (data) => async (dispatch) => {
  dispatch({ type: LOAD_ACTIVITIES_LIST_PENDING });
  try {
    await networkService.send("createActivity", data);
    dispatch({ type: CREATE_ACTIVITY });
  } catch (error) {
    dispatch({ type: LOAD_ACTIVITIES_LIST_ERROR, error });
  }
};

export const editActivityStatus = (data) => async (dispatch) => {
  dispatch({ type: LOAD_ACTIVITIES_LIST_PENDING });
  try {
    await networkService.send("activityStatus", data);
    dispatch({ type: EDIT_ACTIVITY });
  } catch (error) {
    dispatch({ type: LOAD_ACTIVITIES_LIST_ERROR, error });
  }
};

export const editActivity = (data) => async (dispatch) => {
  dispatch({ type: LOAD_ACTIVITIES_LIST_PENDING });
  try {
    await networkService.send("editActivity", data);
    dispatch({ type: EDIT_ACTIVITY });
  } catch (error) {
    dispatch({ type: LOAD_ACTIVITIES_LIST_ERROR, error });
  }
};

export const deleteActivity = (id) => async (dispatch) => {
  dispatch({ type: LOAD_ACTIVITIES_LIST_PENDING });
  try {
    await networkService.send("deleteActivity", id);
    dispatch({ type: DELETE_ACTIVITY });
  } catch (error) {
    dispatch({ type: LOAD_ACTIVITIES_LIST_ERROR, error });
  }
};
export const deleteActivities = (activitiesIDs) => async (dispatch) => {
  dispatch({ type: LOAD_ACTIVITIES_LIST_PENDING });
  try {
    await networkService.send("deleteActivities", activitiesIDs);

    dispatch({ type: DELETE_ACTIVITIES });
    toaster.success("successfully deleted activities");
    // loadActivitiesList()
  } catch (error) {
    dispatch({ type: LOAD_ACTIVITIES_LIST_ERROR, error });
    toaster.error(error);
  }
};
export const getSingleActivity = (id) => async (dispatch) => {
  dispatch({ type: LOAD_ACTIVITIES_LIST_PENDING });
  try {
    let res = await networkService.send("getSingleActivity", { id });
    dispatch({ type: GET_SINGLE_ACTIVITY, payload: res.data.activity });
  } catch (error) {
    dispatch({ type: LOAD_ACTIVITIES_LIST_ERROR, error });
  }
};

export const uploadActivityImage =
  (folderName, cdnName, file) => async (dispatch) => {
    dispatch({ type: LOAD_ACTIVITIES_LIST_PENDING, payload: true });

    try {
      const data = await await axios.post(
        `https://adminapi.appgain.io/aws-cdn.appgain-uploader?folder=${folderName}&bucket=${cdnName}`,
        file,
        {
          headers: {
            "x-api-key": "appgainapikey1024",
          },
        }
      );

      dispatch({ type: ACTIVITY_IMAGE, payload: data.data });
    } catch (error) {
      dispatch({ type: LOAD_ACTIVITIES_LIST_ERROR, payload: error });
    }
  };

export default function reducer(state = defaultState, action) {
  switch (action.type) {
    case LOAD_ACTIVITIES_LIST:
      return {
        ...state,
        isPending: false,
        error: false,
        loaded: true,
        activities: action.activities,
      };
    case LOAD_ACTIVITIES_LIST_PENDING:
      return {
        ...state,
        isPending: true,
        error: false,
        loaded: true,
      };
    case LOAD_ACTIVITIES_LIST_ERROR:
      return {
        ...state,
        isPending: false,
        error: action.error,
        loaded: false,
      };

    case CREATE_ACTIVITY:
      return {
        ...state,
        isPending: false,
        error: false,
        loaded: false,
      };
    case SET_SELECTED_ACTIVITY:
      return {
        ...state,
        activity: action.activity,
      };

    case EDIT_ACTIVITY:
      return {
        ...state,
        isPending: false,
        error: false,
        loaded: false,
      };

    case DELETE_ACTIVITY:
      return {
        ...state,
        isPending: false,
        error: false,
        loaded: false,
      };
    case DELETE_ACTIVITIES:
      return {
        ...state,
        isPending: false,
        error: false,
        loaded: false,
      };
    case GET_SINGLE_ACTIVITY:
      return {
        ...state,
        singleActivity: action.payload,
        isPending: false,
        error: false,
        loaded: false,
      };
    case ACTIVITY_IMAGE:
      return {
        ...state,
        activityImage: action.payload,
        isPending: false,
        loaded: true,
      };
    default:
      return state;
  }
}
