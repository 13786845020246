import _ from "lodash";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import LoadingSpinner from "../../components/LoadingSpinner/LoadingSpinner";
import NGONewsDataTable from "../../components/NGONewsDataTable";
import NewDataTable from "../../components/NewDataTable";
import { deleteNgo, getNgos } from "../../reducers/NGOs";
import { BsPlus } from "react-icons/bs";
// import { Pagination } from "antd";
import { Pagination, PaginationItem } from "@material-ui/lab";
import toaster from "../../modules/toaster";

const selecttableHead = ["orgId", "link", "createdAt", "actions"];

const rowsPerPage = 10;

const NGONews = ({ isPending, allNGOs, getNgos, history, deleteNgo }) => {
  const [data, setData] = useState([]);
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(1);
  const [headSelected, setHeadSelected] = React.useState({
    orgId: true,
    link: true,
    createdAt: false,
    actions: true,
  });

  useEffect(() => {
    getNgos();
  }, []);

  useEffect(() => {
    if (!_.isEmpty(allNGOs)) setData(allNGOs);
  }, [allNGOs]);

  const addNewNGONews = () => {
    history.push("addNewNGONews");
  };
  function getKeyByValue(object, value) {
    return Object.keys(object).filter((key) => object[key] === value);
  }

  const TableHead = getKeyByValue(headSelected, true);

  const handleChange = (event) => {
    setHeadSelected({
      ...headSelected,
      [event.target.name]: event.target.checked,
    });
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };
  const handlePaginationChange = (event, value) => {
    setPage(value);
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;
  const handleDelete = () => {
    if (selected.length === 0) {
      toaster.warning("please select some ngoNews");
    } else {
      Promise.allSettled(
        selected.map((e) => {
          return deleteNgo(e);
        })
      ).then((responseArr) => {
        responseArr.forEach((res) => {
          console.log(res);
        });
      });
      setSelected([]);
    }
  };
  if (isPending) {
    return <LoadingSpinner />;
  }

  return (
    <div>
      <div className="header">
        <h4 className="title">NGONews</h4>
      </div>
      <div className="card">
        {allNGOs && allNGOs.length > 0 ? (
          <NewDataTable
            title={"Add an NGONews"}
            csvTitle={"NGoNews"}
            add={addNewNGONews}
            data={allNGOs}
            tableHead={TableHead}
            selected={selected}
            setSelected={setSelected}
            selecttableHead={selecttableHead}
            handleChange={handleChange}
            headSelected={headSelected}
            handleDelete={handleDelete}
            pagination={
              <Pagination
                // size="small"
                page={page}
                count={Math.ceil(allNGOs.length / 10)}
                // total={projects.length}
                onChange={handlePaginationChange}
              />
            }
          >
            {allNGOs
              ?.slice(
                (page - 1) * rowsPerPage,
                (page - 1) * rowsPerPage + rowsPerPage
              )
              ?.map((row, index) => {
                const isItemSelected = isSelected(row.objectId);
                const labelId = `enhanced-table-checkbox-${index}`;
                return (
                  <NGONewsDataTable
                    key={row.id}
                    ngo={row}
                    handleClick={handleClick}
                    isItemSelected={isItemSelected}
                    labelId={labelId}
                    headSelected={headSelected}
                  />
                );
              })}
          </NewDataTable>
        ) : (
          <div className="noData">
            <p style={{ textAlign: "center" }}>No NgoNews Found</p>
            <button
              className="addButton"
              onClick={() => {
                addNewNGONews;
              }}
            >
              <BsPlus size={24} />
              Add A new NgoNews
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default connect(
  (state) => ({
    isPending: state.NGOs.isPending,
    allNGOs: state.NGOs.ngos,
  }),
  (dispatch) => ({
    getNgos: () => dispatch(getNgos()),
    deleteNgo: (ngo_id) => dispatch(deleteNgo(ngo_id)),
  })
)(NGONews);
