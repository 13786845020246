import axios from "axios";
import { appBackendAppgainUrl, getConfigHeaders } from "../api";

const url = `InMemory`;
const configUrl = `configs`;

const defaultState = {
  inMemoryPersons: [],
  inMemoryPerson: {},
  inMemoryPersonImage: "",
  inMemoryPersonsConfig: {},
  error: null,
  isPending: null,
  loaded: false,
};

const APPROVE_INMEMORYPERSON = "APPROVE_INMEMORYPERSON";
const GET_INMEMORYPERSONS = "GET_INMEMORYPERSONS";
const DELETE_INMEMORYPERSON = "DELETE_INMEMORYPERSON";
const INMEMORYPERSONS_PENDING = "INMEMORYPERSONS_PENDING";
const INMEMORYPERSON_PENDING = "INMEMORYPERSON_PENDING";
const GET_INMEMORYPERSON = "GET_INMEMORYPERSON";
const INMEMORYPERSON_ERROR = "INMEMORYPERSON_ERROR";
const INMEMORYPERSONS_ERROR = "INMEMORYPERSONS_ERROR";
const EDIT_INMEMORYPERSON = "EDIT_INMEMORYPERSON";
const POST_INMEMORYPERSONIMAGE = "POST_INMEMORYPERSONIMAGE";
const CREATE_INMEMORYPERSON = "CREATE_INMEMORYPERSON";
const INMEMORYPERSON_CONFIG = "INMEMORYPERSON_CONFIG";
const INMEMORYPERSON_CONFIG_ERROR = "INMEMORYPERSON_CONFIG_ERROR";

// GET inMemory Persons
export const getInMemoryPersons = () => async (dispatch) => {
  dispatch({
    type: INMEMORYPERSONS_PENDING,
    payload: true,
  });
  try {
    const data = await appBackendAppgainUrl(
      `${url}?order=-createdAt`,
      "get",
      getConfigHeaders()
    );

    dispatch({
      type: GET_INMEMORYPERSONS,
      payload: data.results,
    });
  } catch (error) {
    dispatch({
      type: INMEMORYPERSONS_ERROR,
      payload: error,
    });
  }
};

// GET inMemory Person

export const getInMemoryPerson = (personId) => async (dispatch) => {
  dispatch({
    type: INMEMORYPERSON_PENDING,
    payload: true,
  });
  try {
    const data = await appBackendAppgainUrl(
      `${url}/${personId}`,
      "get",
      getConfigHeaders()
    );
    dispatch({
      type: GET_INMEMORYPERSON,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: INMEMORYPERSON_ERROR,
      payload: error,
    });
  }
};

export const getInMemoryConfig = () => async (dispatch) => {
  try {
    const data = await appBackendAppgainUrl(
      `${configUrl}`,
      "get",
      getConfigHeaders()
    );
    dispatch({
      type: INMEMORYPERSON_CONFIG,
      payload: data.results,
    });
  } catch (error) {
    dispatch({
      type: INMEMORYPERSON_CONFIG_ERROR,
      payload: error,
    });
  }
};

// Create inMemoryPerson
export const createInMemoryPerson =
  (newInMemoryPersonData) => async (dispatch) => {
    dispatch({
      type: INMEMORYPERSONS_PENDING,
      payload: true,
    });
    try {
      const data = await appBackendAppgainUrl(
        `${url}`,
        "post",
        newInMemoryPersonData,
        getConfigHeaders()
      );

      dispatch({
        type: CREATE_INMEMORYPERSON,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: INMEMORYPERSONS_ERROR,
        payload: error,
      });
    }
  };

export const editInMemoryPerson = (id, newInMemoryData) => async (dispatch) => {
  dispatch({
    type: INMEMORYPERSON_PENDING,
    payload: true,
  });
  try {
    await appBackendAppgainUrl(
      `${url}/${id}`,
      "put",
      newInMemoryData,
      getConfigHeaders()
    );
    dispatch({
      type: EDIT_INMEMORYPERSON,
      payload: { id, newInMemoryData },
    });
  } catch (error) {
    dispatch({
      type: INMEMORYPERSON_ERROR,
      payload: error,
    });
  }
};

// Upload person photo
// Image Upload
export const uploadPersonPhoto = (image) => async (dispatch) => {
  dispatch({
    type: INMEMORYPERSONS_PENDING,
    payload: true,
  });
  try {
    const { data } = await axios.post(
      "https://api.appgain.io/5e403f53970b2b000b92de74/upload",
      image,
      {
        headers: {
          appApiKey:
            "ddfa1b658abf0b2f8a6e87d6efcfec7c3d2b4980850b21237febae38664130a6",
        },
      }
    );

    const imageUrl = `https://${data}`;

    dispatch({
      type: POST_INMEMORYPERSONIMAGE,
      payload: imageUrl,
    });

    return { imageURL: imageUrl };
  } catch (error) {
    dispatch({
      type: INMEMORYPERSON_ERROR,
      payload: error,
    });
    throw error;
  }
};

export const approveInMemoryPerson = (id, body) => async (dispatch) => {
  dispatch({
    type: INMEMORYPERSONS_PENDING,
    payload: true,
  });

  try {
    const data = await appBackendAppgainUrl(
      `${url}/${id}`,
      "put",
      body,
      getConfigHeaders()
    );

    dispatch({
      type: APPROVE_INMEMORYPERSON,
      payload: id,
    });
  } catch (error) {
    dispatch({
      type: INMEMORYPERSONS_ERROR,
      paload: error,
    });
  }
};

// Delete inMemory Person
export const deleteInMemoryPerson = (id) => async (dispatch) => {
  dispatch({
    type: INMEMORYPERSONS_PENDING,
    payload: true,
  });
  try {
    await appBackendAppgainUrl(`${url}/${id}`, "delete", getConfigHeaders());

    dispatch({
      type: DELETE_INMEMORYPERSON,
      payload: id,
    });
  } catch (error) {
    dispatch({
      type: INMEMORYPERSONS_ERROR,
      payload: error,
    });
  }
};

export default function reducer(state = defaultState, action) {
  switch (action.type) {
    case INMEMORYPERSON_PENDING:
    case INMEMORYPERSONS_PENDING:
      return { ...state, isPending: true };

    case GET_INMEMORYPERSONS:
      return {
        ...state,
        inMemoryPersons: action.payload,
        loaded: true,
        isPending: false,
      };

    case CREATE_INMEMORYPERSON:
      return {
        inMemoryPersons: [...state.inMemoryPersons, action.payload],
        isPending: false,
        loaded: true,
      };

    case EDIT_INMEMORYPERSON:
      return {
        ...state,
        inMemoryPersons: state.inMemoryPersons?.map((memory) =>
          memory.objectId === action.payload.id
            ? action.payload.newInMemoryData
            : memory
        ),
        isPending: false,
        loaded: true,
      };

    case POST_INMEMORYPERSONIMAGE:
      return {
        ...state,
        inMemoryPersonImage: action.payload,
        isPending: false,
        loaded: true,
      };

    case GET_INMEMORYPERSON:
      return {
        ...state,
        inMemoryPerson: action.payload,
        loaded: true,
        isPending: false,
      };

    case INMEMORYPERSON_CONFIG: {
      return { ...state, inMemoryPersonsConfig: action.payload };
    }
    case APPROVE_INMEMORYPERSON:
      return {
        ...state,
        inMemoryPersons: state.inMemoryPersons?.map((person) =>
          person.objectId === action.payload
            ? { ...person, Approved: !person.Approved }
            : person
        ),
        loaded: true,
        isPending: false,
      };
    case DELETE_INMEMORYPERSON:
      return {
        ...state,
        inMemoryPersons: state.inMemoryPersons.filter(
          (person) => person.objectId !== action.payload
        ),
        loaded: true,
        isPending: false,
      };
    default:
      return { ...state };
  }
}
