import defaultImg from "assets/images/defaultImg.svg";
import Joi from "joi";
import React, { useEffect, useRef, useState } from "react";
import { Button, FormControl } from "react-bootstrap";
import { connect } from "react-redux";
import ConfirmModal from "../../components/ConfirmModal/ConfirmModal";
import {
  createActivity,
  editActivity,
  getSingleActivity,
  uploadActivityImage,
} from "../../reducers/Activities";

const schema = Joi.object({
  nameEN: Joi.string().max(45).required(),
  nameAR: Joi.string().max(45).required(),
  infoAR: Joi.string().max(1000).required(),
  infoEN: Joi.string().max(1000).required(),
  order: Joi.number().min(0).required(),
});

const AddActivity = (props) => {
  const {
    getSelectedActivity,
    selectedActivity,
    history,
    editActivity,
    createActivity,
    uploadImage,
    actImage,
    state,
  } = props;

  const [isEditingMode, setIsEditingMode] = useState(false);
  const [intId, setIntId] = useState("");
  const [order, setOrder] = useState();
  const [infoAR, setInfoAR] = useState("");
  const [infoEN, setInfoEN] = useState("");
  const [nameAR, setNameAR] = useState("");
  const [nameEN, setNameEN] = useState("");
  const [selectedImage, setSelectedImage] = useState();
  const [show, setShow] = useState(false);
  const fileInputRef = useRef();

  const [image, setImage] = useState();
  const [preview, setPreview] = useState();
  console.log(actImage);
  useEffect(() => {
    if (image) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setPreview(reader.result);
      };
      reader.readAsDataURL(image);
    } else {
      setPreview(null);
    }
  }, [image]);
  const activityId =
    window.location.hash.includes("editActivity") &&
    window.location.hash.split("/")[window.location.hash.split("/").length - 1];

  useEffect(() => {
    if (activityId) {
      getSelectedActivity(activityId);
      setIsEditingMode(true);

      // set fields

      setNameEN(selectedActivity.nameEN);
      setNameAR(selectedActivity.nameAR);
      setInfoEN(selectedActivity.infoEN);
      setInfoAR(selectedActivity.infoAR);
      setOrder(selectedActivity.order);
      setIntId(selectedActivity.int_id);
      setPreview(
        `https://cdn.ikhair.net/ikhair/images/activites/${selectedActivity.int_id}.png`
      );
    } else {
      setIsEditingMode(false);
    }
  }, [
    activityId,
    selectedActivity.nameEN,
    selectedActivity.nameAR,
    selectedActivity.infoAR,
    selectedActivity.infoEN,
    selectedActivity.order,
    selectedActivity.int_id,
  ]);

  useEffect(() => {
    if (selectedImage) {
      if (selectedImage.size > 512000) {
        setShow(true);
        setSelectedImage("");
      }
    }
  }, [selectedImage]);
  const uploadLogoHandler = async () => {
    const formData = new FormData();
    formData.append("file", selectedImage);
    if (selectedActivity.int_id) {
      await uploadImage(
        "ikhair/images/activites",
        "content.quantatel.com",
        formData
      );
    } else {
      await uploadImage(
        "ikhair/images/activites",
        "content.quantatel.com",
        formData
      );
    }
  };
  console.log(actImage);

  const handleActivitySubmit = (e) => {
    e.preventDefault();

    const activityData = {
      int_id: intId,
      order,
      infoAR,
      infoEN,
      nameAR,
      nameEN,
      avalibleforCauses: 1,
      activityImage: `https://cdn.ikhair.net/${actImage?.path}`,
    };

    // const { error } = schema.validate({
    //   nameEN,
    //   nameAR,
    //   infoEN,
    //   infoAR,
    //   order,
    // });

    // if (error) {
    //   return toaster.error(error.details[0].message);
    // }

    if (isEditingMode) {
      editActivity({
        activity_data_to_update: activityData,
        id: activityId,
      });
    } else {
      createActivity(activityData);
    }

    // if (activityId) uploadImage(selectedImage, selectedActivity.int_id);
    // else uploadImage(selectedImage, activityData.int_id);

    history.push("/activities");
  };

  const storeImageHandler = (e) => {
    e.preventDefault();
    setSelectedImage(e.target.files[0]);
    const file = e.target.files[0];
    setImage(file);
  };

  return (
    <React.Fragment>
      <div className="header">
        <h4 className="title">
          {isEditingMode
            ? `Edit Activity: ${selectedActivity.nameEN}`
            : "Add New Activity"}
        </h4>
      </div>

      <form
        className="card"
        style={{ padding: "12px 24px" }}
        onSubmit={handleActivitySubmit}
      >
        <div className="formGroup">
          <div className="fieldDiv form-group" style={{ width: "50%" }}>
            <label id="intId" className="control-label">
              int_id
            </label>
            <FormControl
              type="text"
              value={intId}
              name="intId"
              htmlFor="intId"
              placeholder="Enter Int_id"
              onChange={(e) => setIntId(e.target.value)}
              required
            />
          </div>
          <div className="fieldDiv form-group" style={{ width: "50%" }}>
            <label id="order" className="control-label">
              order
            </label>
            <FormControl
              type="number"
              value={order}
              name="order"
              htmlFor="order"
              placeholder="Enter order"
              onChange={(e) => setOrder(parseInt(e.target.value))}
              required
            />
          </div>
        </div>
        <div className="formGroup">
          <div className="fieldDiv form-group" style={{ width: "50%" }}>
            <label id="intId" className="control-label">
              infoAR
            </label>
            <FormControl
              type="text"
              value={infoAR}
              name="infoAR"
              htmlFor="infoAR"
              placeholder="Enter infoAR"
              onChange={(e) => setInfoAR(e.target.value)}
              required
            />
          </div>
          <div className="fieldDiv form-group" style={{ width: "50%" }}>
            <label id="infoEN" className="control-label">
              infoEN
            </label>
            <FormControl
              type="text"
              value={infoEN}
              name="infoEN"
              htmlFor="infoEN"
              placeholder="Enter infoEN"
              onChange={(e) => setInfoEN(e.target.value)}
              required
            />
          </div>
        </div>

        <div className="formGroup">
          <div className="fieldDiv form-group" style={{ width: "50%" }}>
            <label id="nameAR" className="control-label">
              nameAR
            </label>
            <FormControl
              type="text"
              value={nameAR}
              name="nameAR"
              htmlFor="nameAR"
              placeholder="Enter nameAR"
              onChange={(e) => setNameAR(e.target.value)}
              required
            />
          </div>
          <div className="fieldDiv form-group" style={{ width: "50%" }}>
            <label id="nameEN" className="control-label">
              nameEN
            </label>
            <FormControl
              type="text"
              value={nameEN}
              name="nameEN"
              htmlFor="nameEN"
              placeholder="Enter nameEN"
              onChange={(e) => setNameEN(e.target.value)}
              required
            />
          </div>
        </div>
        <div className="fieldDiv form-group">
          <label className="control-label">
            {isEditingMode ? "Change" : "Upload"} Activity Image
            <img
              src={preview || defaultImg}
              alt="default"
              style={{ width: "124px", height: "124px", display: "block" }}
            />{" "}
          </label>
          <div className="file-input">
            <input
              style={{ width: "48px" }}
              type="file"
              accept="image/*"
              placeholder="Choose Activity Image"
              onChange={storeImageHandler}
              className="file"
              id="file"
              ref={fileInputRef}
            />

            <button
              htmlFor="file"
              className="uploadBtn"
              onClick={(event) => {
                event.preventDefault();
                fileInputRef.current.click();
              }}
            >
              {selectedImage || isEditingMode ? "Change " : "Select "}
            </button>
            {/* <p className="file-name"></p> */}
            <button
              className="removeBtn"
              type="button"
              onClick={() => {
                setSelectedImage(null);
                setPreview(null);
              }}
            >
              Remove
            </button>
          </div>
          <div style={{ marginTop: "20px" }}>
            {selectedImage && (
              <React.Fragment>
                {!actImage && <p>Please Upload the image</p>}

                <button
                  style={{ marginTop: ".4em" }}
                  onClick={uploadLogoHandler}
                  className="uploadBtn"
                  type="button"
                >
                  Upload photo
                </button>
              </React.Fragment>
            )}
          </div>
        </div>
        <div className="submit">
          <Button
            // disabled={!edit}
            className="create-edit-form-btn"
            type="submit"
          >
            {isEditingMode ? "Edit" : "Submit"}
          </Button>
        </div>
      </form>
      {show && (
        <ConfirmModal
          handleClose={() => setShow(false)}
          handleShow={() => setShow(true)}
          message={`Oops! Image size is more than 500 kilobyte! Please Choose smaller size image`}
          setShow={setShow}
          show={show}
        ></ConfirmModal>
      )}
    </React.Fragment>
  );
};

export default connect(
  (state) => ({
    selectedActivity: state.Activities.singleActivity,
    actImage: state.Activities.activityImage,
    state: state,
  }),
  (dispatch) => ({
    getSelectedActivity: (id) => dispatch(getSingleActivity(id)),
    editActivity: (data) => dispatch(editActivity(data)),
    createActivity: (data) => dispatch(createActivity(data)),
    uploadImage: (folderName, cdnName, file) =>
      dispatch(uploadActivityImage(folderName, cdnName, file)),
  })
)(AddActivity);
